import React, { useState } from "react";
// import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import TermsandConditions from "../TermsandConditions"

function Footer() {
  const accessToken = JSON.parse(localStorage.getItem("user"));
  const btoken = accessToken?.access;
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="relative-1">
      <div className="footer-bg">
        <div className="wrapper">
          <div className="footer-wrap">
            <div className="footer-1">
              &copy; Copyright 2024 XENFORD. All Rights Reserved.
            </div>
            <div className="footer-2">
              <div className="footerlinks">
                <ul>
                  <li>
                    <Link
                      to={
                        btoken
                          ? "/MainSubScriptionFeatures"
                          : "/SubScriptionFeatures"
                      }
                    >
                      {" "}
                      Subscriptions
                    </Link>
                  </li>
                  <li>
                    <Link to="/AboutUs"> About</Link>
                  </li>

                  <li>
                    <Link to="/ContactUs">Contact</Link>
                  </li>
                  <li>
                    <Link to="/RefundPolicy">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <p
                      className="linkbutton"
                      onClick={() => setShowModal(true)}
                    >
                      Terms and Conditions
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className="modal-overlay-footer">
          <div className="modal-content-footer">
            <button
              className="close-button-footer"
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <div className="terms-content">
              <TermsandConditions />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Footer;
